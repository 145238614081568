import { ContactPerson } from 'ui/components/3-organisms/ContactPerson/ContactPerson';

export const ContactPersonFeature: React.FC<Content.ContactPerson> = ({ content }) => {
	const { color } = content?.properties ?? {};
	const { properties } = content?.properties.include_ref_author ?? {};

	return (
		<ContactPerson
			{...content?.properties}
			bgColor={color}
			image={{
				url: properties?.image?.url,
				altText: properties?.image?.properties?.altText,
				focalPoint: properties?.image?.focalPoint,
			}}
			details={{
				name: properties.fullName,
				role: properties.workTitle,
				email: properties.email,
				phoneNumber: properties.phoneNumber,
				linkedIn: properties.linkedIn,
			}}
		/>
	);
};
